import Container from 'react-bootstrap/Container';

const CustomJumbotron = ({ children }) => {
  return (
    <div className="p-5 mb-4 bg-light rounded-3">
      <div className="container-fluid py-5">
        {children}
      </div>
    </div>
  );
};

const Stores = () => (
  <>
    <Container className="p-3">
      <CustomJumbotron>
        <h1 className="header">Stores</h1>
        <p className="lead">Here we will list all stores.</p>
      </CustomJumbotron>
    </Container>
  </>
);

export default Stores;