import Container from 'react-bootstrap/Container';

const NoPage = () => {
  return (
    <>
        <Container>
        <center>
        <h1>Oops! This Page Was Not Found.</h1>
        <p>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable.</p>
        <img src="https://http.cat/404" alt="404 This Page Was not Found, instead we show a cute cat lost under pages of paper" class="error-image"></img>
        </center>
        </Container>
    </>
  );
}


export default NoPage;