import { BrowserRouter, Routes, Route } from "react-router-dom";

import './App.css';
import 'leaflet/dist/leaflet.css';
import Layout from "./sites/Layout";
import Home from './sites/Home';
import Favorites from './sites/Favorites';
import Map from './sites/Map';
import Profile from "./sites/Profile";
import Stores from "./sites/Stores";
import StoreDetail from "./sites/Store";
import NoPage from "./sites/NoPage";
import AddStore from "./sites/AddStore";

const App = () => {
  return (
    <>
    <Layout>
    <BrowserRouter>
      <Routes>
        <Route index element={<Home />} />
        <Route path="favorites" element={<Favorites />} />
        <Route path="map" element={<Map />} />
        <Route path="stores" element={<Stores />} />
        <Route path="profile" element={<Profile />} />
        <Route path="/store/:storeId" element={<StoreDetail/>} />
        <Route path="add" element={<AddStore />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
    </Layout>
    </>
  );
}

export default App;